import { useMemo, useCallback } from 'react'
import { useRouter } from 'next/router'
import { Button } from '@/components/common'
import { Checkmark } from '@/components/svg'
import {
  Campaign,
  userFollowCampaign,
  UserFollowingCampaign,
} from '@/services/api/campaignV2/campaign'
import useAuth from '@/services/hooks/useAuth'

interface Props {
  campaign: Campaign
  followingCampaign: UserFollowingCampaign | null
  setFollowingCampaign: (following: UserFollowingCampaign) => void
  sectionName: 'body' | 'sidebar' | 'hero'
  style: object
  'data-cy': 'project-cta-button' | 'closed-offering-button'
  className: string
  color?: string
  rounded?: boolean
}

const FollowButton = ({
  campaign,
  followingCampaign,
  setFollowingCampaign,
  sectionName,
  color = 'transparent',
  rounded = true,
  className,
  ...rest
}: Props) => {
  const { slug } = campaign
  const router = useRouter()
  const { user, openModal } = useAuth()

  const ctaText = useMemo(() => {
    if (followingCampaign?.following && slug === followingCampaign.campaign) {
      return 'Already Following'
    }
    return 'Follow'
  }, [followingCampaign, campaign, className])

  const followCampaign = useCallback(async () => {
    return userFollowCampaign(slug)
  }, [slug])

  const handleClick = useCallback(async () => {
    if (user && !followingCampaign) {
      const fc = await followCampaign()
      setFollowingCampaign(fc)
    }
    if (!user) {
      openModal('login')
    }
  }, [
    followingCampaign,
    followCampaign,
    campaign.slug,
    router.asPath,
    ctaText,
    sectionName,
    user,
  ])

  return (
    <Button
      color={color as 'transparent'}
      variant="secondary"
      onClick={handleClick}
      disabled={
        !!followingCampaign?.following && slug === followingCampaign.campaign
      }
      rounded={rounded}
      className={className}
      {...rest}
    >
      <span className="flex items-center justify-center">
        {followingCampaign?.following &&
          slug === followingCampaign.campaign && (
            <Checkmark className="w-5 h-5 mr-2" />
          )}
        <span>{ctaText}</span>
      </span>
    </Button>
  )
}

export default FollowButton
