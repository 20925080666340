import useApi, { ApiRes } from '@/services/hooks/useApiV2'
import {
  getUserFollowingCampaign,
  UserFollowingCampaign,
} from '@/services/api/campaignV2/campaign'
import { User } from '@/services/api/user/user'

const useFollowingCampaign = (slug: string, user: User | null | undefined) => {
  const {
    data: followingCampaign,
    isLoading: followingCampaignLoading,
    isError: followingCampaignError,
  }: ApiRes<UserFollowingCampaign> = useApi({
    key: user && slug ? `user-following-campaign/${user.id}/${slug}` : false,
    request: () => getUserFollowingCampaign(slug),
  })

  return {
    followingCampaign,
    isLoading: followingCampaignLoading,
    isError: followingCampaignError,
  }
}

export default useFollowingCampaign
