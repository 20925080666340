import { useMemo, useCallback, useState } from 'react'
import { useRouter } from 'next/router'
import { FilledButton, HollowButton, CardModal } from '@/components/common'
import { Checkmark } from '@/components/svg'
import useAuth from '@/services/hooks/useAuth'
import InvestSubscribeForm from './InvestSubscribeForm'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { Campaign } from '@/services/api/campaignV2/campaign'

interface Props {
  campaign: Campaign
  subscribedEmail: string | null
  setSubscribedEmail: (email: string) => void
  sectionName: 'body' | 'sidebar' | 'hero'
  style: object
  'data-cy': 'project-cta-button' | 'closed-offering-button'
  className: string
  color?: string
  rounded?: boolean
  size?: string
}

const InvestButton = ({
  campaign,
  subscribedEmail,
  setSubscribedEmail,
  sectionName,
  color = 'copper',
  rounded = true,
  ...rest
}: Props) => {
  const [showSubscribeModal, setShowSubscribeModal] = useState(false)
  const { currentlyFunding, regulationType, slug } = campaign
  const router = useRouter()
  const { user, openModal } = useAuth()

  const ctaText = useMemo(() => {
    if (!currentlyFunding && !subscribedEmail) {
      return 'Subscribe To Updates'
    }

    if (!currentlyFunding && subscribedEmail) {
      return 'Subscribed'
    }

    if (regulationType === 'testing_the_waters') {
      return 'Express Interest'
    }

    return 'Back This Project'
  }, [currentlyFunding, regulationType, subscribedEmail])

  const expressInterestRedirect = `/express-interest/${slug}`
  const investmentRedirect = `/invest/${slug}`
  const redirect =
    regulationType === 'testing_the_waters'
      ? expressInterestRedirect
      : investmentRedirect

  const routeToInvestment = useCallback(() => {
    if (user) {
      router.push(redirect)
    } else {
      openModal('verifyAccount', redirect)
    }
  }, [user, openModal, redirect, router])

  const subscribeToUpdates = useCallback(() => {
    setShowSubscribeModal(true)
  }, [setShowSubscribeModal])

  const handleClick = useCallback(() => {
    if (!currentlyFunding) {
      subscribeToUpdates()
    } else {
      routeToInvestment()
    }
    SegmentHandler.track('On Tap', {
      project: campaign.slug,
      location: router.asPath,
      section_name: sectionName,
      element_name: 'cta',
      value: ctaText,
      action_type: 'tap',
    })
    SegmentHandler.track(
      'Click Campaign CTA',
      { ctaText, project: campaign.slug },
      { suppressSegmentTrack: true }
    )
  }, [
    routeToInvestment,
    subscribeToUpdates,
    currentlyFunding,
    campaign.slug,
    router.asPath,
    ctaText,
    sectionName,
  ])

  const Button = subscribedEmail ? HollowButton : FilledButton

  return (
    <>
      <Button
        color={color}
        onClick={handleClick}
        disabled={!!subscribedEmail}
        rounded={rounded}
        {...rest}
      >
        <div className="flex items-center justify-center">
          {subscribedEmail && <Checkmark className="w-5 h-5 mr-2" />}
          <span>{ctaText}</span>
        </div>
      </Button>

      <CardModal isOpen={showSubscribeModal} setIsOpen={setShowSubscribeModal}>
        <div className="w-[570px] max-w-full px-8 py-6 sm:px-16 sm:py-8">
          <CardModal.Title className="mb-4 text-2xl">
            {subscribedEmail
              ? 'Unsubscribe from Updates'
              : 'Subscribe to Updates'}
          </CardModal.Title>
          <InvestSubscribeForm
            campaignSlug={campaign.slug}
            subscribedEmail={subscribedEmail}
            setSubscribedEmail={setSubscribedEmail}
            closeModal={() => setShowSubscribeModal(false)}
          />
        </div>
      </CardModal>
    </>
  )
}

export default InvestButton
