import classNames from 'classnames'
import React, { CSSProperties, LegacyRef } from 'react'

import ProgressBar from './InvestComponents/ProgressBar'
import AmountRaised from './InvestComponents/AmountRaised'
import Backers from './InvestComponents/Backers'
import SellingFast from './InvestComponents/SellingFast'
import TimeRemaining from './InvestComponents/TimeRemaining'
import MinInvestment from './InvestComponents/MinInvestment'
import Buffer from './InvestComponents/Buffer'
import PriorRaise from './InvestComponents/PriorRaise/PriorRaise'
import InvestButton from './InvestComponents/InvestButton'
import CampaignDisclaimer from './InvestComponents/CampaignDisclaimer'
import useCampaign from '@/services/hooks/useCampaign'
import InvestmentDetails from './InvestComponents/InvestmentDetails'
import { useCampaignAssets } from '@/services/hooks/useCampaignAssets'
import { ISectionAnticipatedRegulationTypeFields } from 'src/types/generated/contentful'
import {
  Campaign,
  UserFollowingCampaign,
} from '@/services/api/campaignV2/campaign'
import useABTest from '@/services/hooks/useABTest'
import FollowButton from '@/components/pages/CampaignPages/InvestComponents/FollowButton'
import { isWhiteLabel } from '../../../utils/whiteLabel'
import PifButton from '@/components/pages/CampaignPages/InvestComponents/PifButton'

interface Props {
  anticipatedRegulation: ISectionAnticipatedRegulationTypeFields
  campaign: Campaign
  className?: string
  investButtonWrapperRef?: LegacyRef<HTMLDivElement>
  sectionName: 'body' | 'sidebar' | 'hero'
  setSubscribedEmail: (email: string) => void
  showDetails?: boolean
  style?: CSSProperties
  subscribedEmail: string | null
  followingCampaign?: UserFollowingCampaign | null
  setFollowingCampaign?: (fc: UserFollowingCampaign) => void
  showFollowing: boolean
  showPif: boolean
  theme?: 'dark' | 'light'
}

const InvestCard: React.FC<Props> = ({
  anticipatedRegulation,
  campaign,
  className,
  investButtonWrapperRef,
  sectionName,
  setSubscribedEmail,
  showDetails,
  style = {},
  subscribedEmail,
  followingCampaign,
  setFollowingCampaign,
  showFollowing,
  showPif,
  theme = 'dark',
}) => {
  const { campaign: resolvedCampaign = campaign } = useCampaign(campaign.slug)

  const { regulationType, currentlyFunding } = resolvedCampaign

  return (
    <div className={className} style={style}>
      {regulationType !== 'testing_the_waters' && (
        <ProgressBar
          campaign={resolvedCampaign}
          trackColor={theme === 'light' ? 'white' : 'black'}
        />
      )}

      <div
        className={classNames({
          'mt-2 flex justify-between': true,
          'text-black': theme === 'dark',
          'text-white': theme === 'light',
        })}
      >
        <div className="w-full flex md:block">
          <div>
            <AmountRaised campaign={resolvedCampaign} className="lg:mt-1" />

            <Backers campaign={resolvedCampaign} className="mt-3" />
          </div>
        </div>
        {currentlyFunding && regulationType !== 'testing_the_waters' && (
          <TimeRemaining campaign={resolvedCampaign} className="lg:mt-1 " />
        )}
      </div>

      <Buffer
        campaign={resolvedCampaign}
        className={classNames({
          'my-3': true,
          'text-black': theme === 'dark',
          'text-white': theme === 'light',
        })}
      />

      <SellingFast
        campaign={resolvedCampaign}
        className={classNames({
          'my-2': true,
          'text-black': theme === 'dark',
          'text-white': theme === 'light',
        })}
      />

      <div ref={investButtonWrapperRef}>
        <InvestButton
          campaign={resolvedCampaign}
          subscribedEmail={subscribedEmail}
          setSubscribedEmail={setSubscribedEmail}
          style={{ maxWidth: 420 }}
          data-cy={
            currentlyFunding ? 'project-cta-button' : 'closed-offering-button'
          }
          className="block w-full mt-2 mx-auto"
          sectionName={sectionName}
        />
      </div>

      {sectionName !== 'hero' &&
        showFollowing &&
        followingCampaign !== undefined &&
        setFollowingCampaign && (
          <div>
            <FollowButton
              campaign={resolvedCampaign}
              followingCampaign={followingCampaign}
              setFollowingCampaign={setFollowingCampaign}
              style={{ maxWidth: 420 }}
              data-cy={
                currentlyFunding
                  ? 'project-cta-button'
                  : 'closed-offering-button'
              }
              className="block w-full mt-2 mx-auto"
              sectionName={sectionName}
            />
          </div>
        )}

      {sectionName !== 'hero' && showPif && (
        <div>
          <PifButton
            url="https://shop.angel.com/products/pay-it-forward-david%E2%84%A2?utm_source=thedavidmovie&utm_medium=referral&utm_campaign=investdavid"
            className="block w-full mt-2 mx-auto"
          />
        </div>
      )}

      {currentlyFunding && regulationType !== 'testing_the_waters' && (
        <div
          className={classNames({
            'text-black': theme === 'dark',
            'text-white': theme === 'light',
          })}
        >
          <MinInvestment
            campaign={resolvedCampaign}
            className="mt-3 text-center font-light"
          />
        </div>
      )}

      {sectionName !== 'hero' && (
        <div className="mt-4 mb-2">
          <PriorRaise
            campaign={resolvedCampaign}
            className={classNames({
              'border-t last:border-b py-2': true,
              'text-black border-gray-8': theme === 'dark',
              'text-white border-[rgba(255,255,255,0.5)]': theme === 'light',
            })}
          />

          {showDetails && (
            <InvestmentDetails
              campaign={resolvedCampaign}
              className={classNames({
                'border-t last:border-b py-2': true,
                'text-black border-gray-8': theme === 'dark',
                'text-white border-[rgba(255,255,255,0.5)]': theme === 'light',
              })}
            />
          )}
        </div>
      )}

      <CampaignDisclaimer
        className={classNames({
          'mt-4 px-1 mx-auto text-[11px] leading-tight tracking-none': true,
          'text-gray': theme !== 'light',
          'text-white': theme === 'light',
        })}
        campaign={resolvedCampaign}
        anticipatedRegulation={anticipatedRegulation}
      />
    </div>
  )
}

const ABPif = (props: Props) => {
  const [decision] = useABTest('invest_card_pif')
  if (
    typeof decision === 'object' &&
    decision.variationKey === 'on' &&
    isWhiteLabel()
  ) {
    return <InvestCard {...props} showPif={true} />
  }
  return <InvestCard {...props} />
}

const ABInvestCard = (props: Props) => {
  const assets = useCampaignAssets()
  // invest_card_follow and invest_card_pif should never run at the same time. invest_card_details should always run.
  const [detailsDecision] = useABTest('invest_card_details')
  const [followDecision] = useABTest('invest_card_follow')
  const detailsOn =
    typeof detailsDecision === 'object' &&
    detailsDecision.variationKey === 'on' &&
    !assets.valuation

  if (
    typeof followDecision === 'object' &&
    followDecision.variationKey === 'on' &&
    isWhiteLabel()
  ) {
    return (
      <InvestCard {...props} showDetails={detailsOn} showFollowing={true} />
    )
  }
  return <ABPif {...props} showDetails={detailsOn} />
}

export default ABInvestCard
