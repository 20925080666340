import { useCallback } from 'react'
import { useRouter } from 'next/router'
import { Button } from '@/components/common'

interface Props {
  url: string
  className: string
  color?: string
  rounded?: boolean
}

const PifButton = ({
  url,
  color = 'transparent',
  rounded = true,
  className,
  ...rest
}: Props) => {
  const router = useRouter()

  const handleClick = useCallback(async () => {
    router.push(url)
  }, [router])

  return (
    <>
      <Button
        color={color as 'transparent'}
        variant="secondary"
        onClick={handleClick}
        rounded={rounded}
        className={className}
        style={{ maxWidth: 420 }}
        {...rest}
      >
        <div className="flex items-center justify-center">
          <span>Pay it Forward</span>
        </div>
      </Button>
    </>
  )
}

export default PifButton
