import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react'
import YouTubePlayer from 'yt-player'
import { Scrim, VideoBox } from '@/components/common'
import CampaignHeading from '../CampaignHeading'
import InvestCard from '../InvestCard'
import useYoutubeBackgroundImage from '@/services/hooks/useYoutubeBackgroundImage'
import { FaPlay } from 'react-icons/fa'
import useWindowSize from '@/services/hooks/useWindowSize'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { Campaign } from '@/services/api/campaignV2/campaign'
import { ISectionAnticipatedRegulationTypeFields } from '../../../../types/generated/contentful'
import tailwind from '../../../../../tailwind.config'

const VIDEO_BREAK = parseInt(tailwind.theme.screens.xl, 10)

export interface Props {
  video: string
  campaign: Campaign
  subscribedEmail: string | null
  setSubscribedEmail: Dispatch<SetStateAction<string | null>>
  setModalVideo: (videoId: string) => void
  modalIsOpen: boolean
  anticipatedRegulation: ISectionAnticipatedRegulationTypeFields
  aspectRatio?: number
}

const VideoHero: React.FC<Props> = ({
  video,
  campaign,
  subscribedEmail,
  setSubscribedEmail,
  setModalVideo,
  modalIsOpen,
  anticipatedRegulation,
  aspectRatio,
}) => {
  const [origin, setOrigin] = useState<string | null>(null)
  const [isLoaded, setLoaded] = useState(false)
  const { src: backgroundSrc } = useYoutubeBackgroundImage({ video, size: '1' })
  const playerRef = useRef<YouTubePlayer | null>(null)
  const videoRef = useRef<HTMLDivElement>(null)
  const id = `video-hero-${video}`

  const { innerWidth: winWidth } = useWindowSize()

  const handlePlayButtonClick = useCallback(() => {
    playerRef.current?.pause()
    setModalVideo(video)

    SegmentHandler.track('On Tap', {
      location: `/${campaign.slug}`,
      project: campaign.slug,
      section_name: 'hero',
      element_name: 'cta',
      value: 'Play Video',
      action_type: 'tap',
    })
  }, [setModalVideo, campaign, video])

  const offsets = useMemo(() => {
    const availableSpace = Math.max(0, (winWidth - VIDEO_BREAK) / 2)
    const videoOffset = availableSpace > 275 ? 150 : availableSpace

    return {
      video: videoOffset * -1,
      scrimStop: availableSpace + videoOffset,
    }
  }, [winWidth])

  useEffect(() => {
    if (!origin || !video) {
      setOrigin(window.location.origin)
      return
    }

    try {
      playerRef.current = new YouTubePlayer(`#${id}`)
      playerRef.current?.load(video)

      playerRef.current?.on('cued', () => {
        playerRef.current?.play()
      })

      playerRef.current?.on('playing', () => {
        setLoaded(true)
      })

      playerRef.current?.on('ended', () => {
        playerRef.current?.play()
      })
    } catch (err) {
      setLoaded(true)
    }

    return () => {
      playerRef.current?.destroy()
    }
  }, [id, video, setLoaded, origin, setOrigin])

  useEffect(() => {
    if (!modalIsOpen && isLoaded) {
      playerRef.current?.play()
    }
  }, [modalIsOpen, isLoaded])

  return (
    <div className="relative bg-black">
      <div className="absolute inset-0 overflow-hidden">
        <div
          className="absolute"
          style={{ top: -20, bottom: -20, left: -20, right: -20 }}
        >
          <img
            src={backgroundSrc}
            className="object-cover"
            style={{
              width: '100%',
              height: '100%',
              filter: 'blur(8px)',
              margin: -5,
            }}
          />
        </div>
      </div>

      <div
        className="relative mx-auto bg-black flex items-center"
        style={{
          maxWidth: VIDEO_BREAK,
          transform: `translateX(${offsets.video}px)`,
          opacity: isLoaded ? 1 : 0,
          transition: 'opacity 0.3s ease-in-out',
        }}
        ref={videoRef}
      >
        {video && origin && (
          <VideoBox
            id={id}
            video={video}
            lazyload={false}
            fadeInOnLoad={false}
            params={{ controls: 0, mute: 1, enablejsapi: 1, origin }}
            className="w-full pointer-events-none"
            campaignSlug={campaign.slug}
            doSegmentTracking={false}
            aspectRatio={aspectRatio}
          />
        )}
      </div>

      <Scrim
        startColor="rgba(0,0,0,1)"
        startColorStop={`${offsets.scrimStop}px`}
        position="right"
        distance="50%"
        className="hidden xl:block"
      />

      <div
        className="hidden xl:block absolute inset-0 z-10"
        style={{ top: '50%', transform: 'translateY(-50%)' }}
      >
        <div
          className="relative w-full h-full flex justify-end items-center mx-auto"
          style={{ maxWidth: VIDEO_BREAK }}
        >
          <div style={{ maxWidth: 460 }}>
            <CampaignHeading {...campaign} className="px-6" />

            <InvestCard
              campaign={campaign}
              subscribedEmail={subscribedEmail}
              setSubscribedEmail={setSubscribedEmail}
              theme="light"
              className="px-6 mt-6"
              sectionName="hero"
              anticipatedRegulation={anticipatedRegulation}
              investButtonWrapperRef={null}
              showFollowing={false}
              showPif={false}
            />
          </div>
        </div>
      </div>

      <div
        className="hidden xl:block absolute z-20 transition"
        style={{
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          marginLeft: `${offsets.video}px`,
        }}
      >
        <button
          className="block text-white border-0 outline-none focus:outline-none opacity-50 hover:opacity-100 transition-opacity"
          style={{
            fontSize: 90,
          }}
          onClick={handlePlayButtonClick}
          aria-label="Play Video"
        >
          <FaPlay />
        </button>
      </div>
    </div>
  )
}

export default VideoHero
